.wrapper {
  @apply text-sm max-w-7xl mx-auto pt-8 pb-4 px-8 sm:px-8 lg:pt-16 lg:px-8;
}

.wrapper h3 {
  @apply font-semibold text-gray-400 tracking-wider uppercase;
}

.links {
  @apply mb-8;
}

.links ul {
  @apply mt-4 space-y-4;
}

.links ul a {
  @apply text-gray-500 hover:text-gray-900;
}

.links hr {
  @apply mr-14;
}
