.header {
  @apply sticky top-0 w-full z-30 bg-color-primary;
}

.headerInner {
  @apply flex items-center text-sm h-[--topnav-height] max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

.headerLogo {
  @apply flex flex-1 md:flex-none;
}

.headerLogo img {
  @apply h-6 md:h-8;
}

.headerNav {
  @apply hidden md:flex space-x-4 lg:space-x-8  flex-1 justify-start md:ml-8;
}

.headerNavRight {
  @apply hidden md:flex items-center space-x-4 lg:space-x-8;
}

/* adjust font size for screens 768px to 795px so that the menu won't break due to lack of real estate */
@media (max-width: 795px) {
  .headerNavRight,
  .headerNav {
    @apply text-xs;
  }
}

.headerNavLink {
  @apply whitespace-nowrap font-medium text-white hover:text-gray-300;
}

.headerNavButton {
  @apply ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm font-medium text-color-primary hover:text-color-secondary-hov bg-white;
}

.headerHamburger {
  @apply md:hidden;
}

.headerHamburger button {
  @apply bg-white rounded-md p-1.5 text-gray-400;
}

.headerHamburger button svg {
  @apply h-6 w-6;
}

.mobileNav {
  @apply md:hidden fixed top-0 left-0 bottom-0 w-full z-20 bg-white overflow-y-auto overscroll-contain transition;
  transition-property: left;
}

.mobileNavHidden {
  @apply left-full;
}

.mobileNavInner {
  @apply px-4 pb-2 pt-[--topnav-height];
}

.mobileNavGroupTitle {
  @apply text-sm text-gray-400 uppercase my-4;
}

.mobileNavGroup .mobileNavLink {
  @apply ml-4;
}

.mobileNavLink {
  @apply block my-6 font-medium text-gray-900 hover:text-gray-700;
}

.mobileNavButton {
  @apply my-6 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov;
}
